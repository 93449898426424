import * as React from 'react'
import Modal from '../components/modal'
import { Helmet } from 'react-helmet'

const Form = () => {
  return (
    <>
      <Helmet>
        <title>Looking for a community pantry?</title>
      </Helmet>
      <main className="bg-main-1 h-screen">
        <Modal view="form" />
      </main>
    </>
  )
}

export default Form
